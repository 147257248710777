import { useState, useRef, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import styled from '@emotion/styled';
import './theme';
import moment from 'moment';

const Wrapper = styled.div({
  input: {
    outline: 0
  }
});

export default function ({
  value, onChange, min, name, className, placeholder = 'YYYY-MM-DD', ...props
}) {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();
  useEffect(() => {
    const input = inputRef.current.container.querySelector('input');
    const closeOnEsc = e => {
      if (e.key === 'Escape' || e.key === 'Backspace') {
        setFocused(false);
      }
    }

    input.addEventListener('keydown', closeOnEsc);

    return () => input.removeEventListener('keydown', closeOnEsc);
  }, []);


  const today = new Date();
  const input = inputRef.current?.container.querySelector('input');

  return (
    <Wrapper className={`${className} ${!value && input?.value ? 'is-invalid' : ''}`} aria-expanded={!!value || !!input?.value || focused}>
      <SingleDatePicker
        ref={inputRef}
        // name={name}
        block
        placeholder={placeholder}
        // pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" // react-dates doesn't allow it
        onDateChange={date => onChange({ target: { name, value: date ? date.format?.('YYYY-MM-DD') : null } })}
        displayFormat="YYYY-MM-DD"
        isDayHighlighted={d => d.isSame(today, 'day')}
        isOutsideRange={min ? d => d.isBefore(min) : undefined}
        date={typeof value === 'string' ? moment(value) : value}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        numberOfMonths={1}
        enableOutsideDays
        small
        hideKeyboardShortcutsPanel
        // withPortal={window.matchMedia('(max-width: 692px)').matches} // avoid portal to make it more obious user can type in
        {...props}
      />
    </Wrapper>
  );
}
