import styled from '@emotion/styled';


const Wrapper = styled.div({
  maxWidth: 560,
  margin: '3em auto 0',
  '@media (max-width: 576px)': {
    textAlign: 'center',
  },
});

export default ({ className = 'container', ...props }) => <Wrapper className={className} {...props} />;