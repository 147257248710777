import styled from '@emotion/styled';

import store from '../store';

const LogoImg = styled.img({
  objectFit: 'contain',
  height: 32,
});

const LogoText = styled.span({
  fontWeight: 700,
  filter: 'brightness(.8)',
  fontSize: 21,
  verticalAlign: 'middle',
})


export default function Logo() {
  const { settings } = store.get();

  if (settings.brand.logo) return <LogoImg id="logo" src={settings.brand.logo} alt={settings.companyName} />;

  // default logo, useful for new setups
  return (
    <span>
      <LogoImg as="svg" id="logo" viewBox="0 0 200 230" xmlns="http://www.w3.org/2000/svg" fill="var(--brand)">
        <path d="M93.7037 219.684V122.8L10.4282 74.7207V171.845L93.7037 219.684Z" />
        <path d="M22.2133 57.3889L104.155 10.3162L187.24 58.0459L104.222 104.737L22.2133 57.3889Z" />
        <path d="M197.752 64.0848L197.881 64.3147V64.1591L197.752 64.0848Z" />
        <path d="M197.881 76.0423C197.881 113.456 197.881 134.432 197.881 171.845L114.606 219.684V122.878L197.881 76.0423Z" />
        <path d="M22.2133 57.3889L104.155 10.3162L187.24 58.0459L104.222 104.737L22.2133 57.3889Z" />
      </LogoImg>
      <LogoText className="text-primary ls-1 ms-1">{settings.companyName}</LogoText>
    </span>
  );
}
