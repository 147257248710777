import loadImage from 'blueimp-load-image';

function toBase64(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result.slice(reader.result.split(',', 1)[0].length + 1)); // trim data:*/*;base64,
    reader.onerror = reject;
    reader.readAsDataURL(file);
  })
}


export default async function processData(customFields, cfSettings) {
  const processedEntries = await Promise.all(
    Object.entries(customFields).map(async ([k, v]) => {
      const cfSetting = cfSettings.find(o => o.code === k);

      if (v?.url?.startsWith('blob:') && v.file) { // blob file needing to be uploaded
        const name = v.file.name;

        if (v.file.type.startsWith('image/') && v.file.type !== 'image/svg+xml' || v.file.type === 'image/svg+xml' && v.file.size > 1e6) {
          // resize all images, except small svgs
          v.file = await loadImage(v.file, { canvas: true, maxWidth: 1200, maxHeight: 1200 })
            .then(data => new Promise((resolve) => data.image.toBlob(resolve, v.file.type)));
        }

        const data = await toBase64(v.file);
        return [k, { name, data }];
      }

      if (v === '') {
        return [k, null];
      }

      if (cfSetting?.type === 'number' || cfSetting?.type === 'integer') {
        return [k, +v];
      }

      return [k, v];
    })
  );
  return Object.fromEntries(processedEntries);
}
