import { WurdMarkdown } from 'wurd-react';
import store from '../store';
import DynamicLoader from 'components/dynamic-loader';


const LazyStripePlugin = DynamicLoader(() => import('./stripe'));
const LazyGmopgPlugin = DynamicLoader(() => import('./gmopg'));
const LazyBraintreePlugin = DynamicLoader(() => import('./braintree'));
const LazyCybersourcePlugin = DynamicLoader(() => import('./cybersource'));

export default props => {
  const { settings } = store.get();

  switch (settings.billing) {
    case 'stripe': return <LazyStripePlugin {...props} />;
    case 'gmopg': return <LazyGmopgPlugin {...props} />;
    case 'braintree': return <LazyBraintreePlugin {...props} />;
    case 'cybersource': return <LazyCybersourcePlugin {...props} />;

    default: return (
      <WurdMarkdown
        id="billing.none"
        vars={{
          siteUrl: settings.siteUrl,
          helpUrl: settings.helpUrl,
          helpPhone_full: settings.helpPhone?.full,
          helpPhone_display: settings.helpPhone?.display
        }}
      />
    );
  }
};
